import { Customer } from './customer.model';
import { Discount } from './discount.model';
import { Owner, OwnerAddress } from './owner.model';
import { Veterinarian } from './veterinarian.model';
import { Patient } from './patient.model';
import { ListItem, ListItemInfo } from './list-item.model';
import { Accession } from './accession.model';
import { CanceledTest, CancelReasonCode, Test } from './test.model';
import { Sample, SampleFormValue } from './sample.model';
import { AnimalTypeInputValue } from './animal-type.model';
import { PatientAge } from '@lims-common-ux/lux';
import { PatientSex } from './patient-sex.model';
import { FormGroup } from '@angular/forms';
import { SampleTestAssociation } from '../../sample-association/sample-test-association';
import { ObjectUtil, StringUtil } from '../utils';
import { KnownTags, Order, Tags } from './order.model';
import { ElectronicOrderFieldValue } from '../../order-entry/electronic-order/electronic-order-field/electronic-order-field-value.model';

export class OrderForm {
  accessionNumber: string = '';
  cancelReasonCode: CancelReasonCode = null;
  collectionDate: string = '';
  customer: Customer = null;
  customerBarcode: string = '';
  customerMessages: ListItem[] = [];
  discount: Discount = null;
  escalateToCustomerSupport: boolean = false;
  orderedTests: Test[] = [];
  owner: Owner = { name: '' };
  patient: Patient = new Patient();
  petOwnerBillingEnabled: boolean = false;
  pimsOrderId: string = '';
  requisitionInfo: ElectronicOrderFieldValue = {
    requisitionId: '',
    electronicOrderId: '',
    electronicOrderVersion: null,
  };
  samples: SampleFormValue = {
    samples: [],
    missingSample: false,
  };
  tags: Tags = {};
  veterinarian: Veterinarian = new Veterinarian();
  operationalId?: any;

  constructor() {}

  // Create normalized ListItem collections for ListItem UX component
  //  based on an unknown reference data type
  static getListItems(items: any[]) {
    return items.map((item) => {
      const listItemInfo = item as ListItemInfo;
      listItemInfo.initialText = listItemInfo.text;
      return { value: item, info: listItemInfo } as ListItem;
    });
  }

  static getOrderedTests(accession: Accession): Test[] {
    const orderedTests: Test[] = accession.orderedTests.map((test: Test) => {
      const orderedTest = Test.createTest(test);

      orderedTest.isAddOn = accession.addOns.filter((existingAddOn) => existingAddOn.testId === test.testId).length > 0;

      orderedTest.cancelReasonCode = accession.canceledTests?.filter(
        (canceledTests) => canceledTests.testId === test.testId
      )[0]?.cancelReasonCode;

      return orderedTest;
    });

    return orderedTests;
  }

  static createOrderFormFromAccession(accession: Accession): OrderForm {
    const orderForm = new OrderForm();

    orderForm.accessionNumber = accession.accessionNumber || '';
    orderForm.collectionDate = accession.collectionDate || '';
    orderForm.cancelReasonCode = accession.cancelReasonCode || null;
    orderForm.customer = accession.customer || null;
    orderForm.customerBarcode = accession.customerBarcode || '';
    orderForm.customerMessages =
      (accession.customerMessages && OrderForm.getListItems(accession.customerMessages)) || [];
    orderForm.discount = (accession.discount && new Discount(accession.discount)) || null;
    orderForm.escalateToCustomerSupport = accession.tags?.[KnownTags.ESCALATION] === 'customer-support';
    orderForm.orderedTests = OrderForm.getOrderedTests(accession) || [];
    orderForm.owner = accession.owner || { name: '' };
    orderForm.patient = accession.patient || new Patient();
    orderForm.petOwnerBillingEnabled = accession.petOwnerBillingEnabled || false;
    orderForm.pimsOrderId = accession.pimsOrderId || '';
    orderForm.requisitionInfo = {
      requisitionId: accession.requisitionId || '',
      electronicOrderId: accession.electronicOrderId,
      electronicOrderVersion: accession.electronicOrderVersion,
    };
    orderForm.samples = accession.samples?.length
      ? {
          samples: accession.samples.map((sample) => Sample.createFromAccessionedSample(sample)),
          missingSample: false,
        }
      : {
          samples: [],
          missingSample: true,
        };
    orderForm.tags = accession.tags || {};
    orderForm.veterinarian = accession.veterinarian || new Veterinarian();
    orderForm.operationalId = accession.operationalId;

    return orderForm;
  }
}

// TODO: once LG-7176 is in place, use it in typed angular reactive form
export class OrderAngularForm {
  accessionNumber: string;
  animalType: AnimalTypeInputValue;
  collectionDate: string = '';
  customAnimalType: string;
  customerCode: string = null;
  customerMessages: ListItem[] = [];
  discount: Discount = null;
  emailFax: string;
  escalateToCustomerSupport: boolean = false;
  microchipId: string;
  ownerName: string;
  patientAge: PatientAge;
  patientName: string;
  patientSex: PatientSex;
  pimsOrderId: string;
  requisitionInfo: ElectronicOrderFieldValue;
  samples: Sample[];
  testCodes: Test[];
  vetName: string;

  // De-dupe ordered tests for save/update payload
  static getOrderedTestIds(tests: Test[]): string[] {
    const orderedTestIds = [];

    if (Array.isArray(tests)) {
      tests.forEach((testCode) => {
        const found = orderedTestIds.find((orderedTestId) => orderedTestId === testCode.testId);
        if (!found) {
          orderedTestIds.push(testCode.testId);
        }
      });
    }
    return orderedTestIds;
  }

  static getAddOnTestIds(tests: Test[]): string[] {
    const addOnTestIds = [];

    if (Array.isArray(tests)) {
      tests.forEach((testCode) => {
        const isCanceledTest = tests.filter(
          (canceledTest) =>
            canceledTest.testId === testCode.testId && canceledTest.cancelReasonCode && !testCode.isUncanceled
        )[0];

        if (!!testCode.isAddOn && testCode.testId !== isCanceledTest?.testId) {
          addOnTestIds.push(testCode.testId);
        }
      });
    }

    return addOnTestIds;
  }

  static getCanceledTests(tests: Test[]) {
    const canceledTests = [] as CanceledTest[];

    if (Array.isArray(tests)) {
      tests.forEach((test) => {
        const isUncanceledTest = tests.filter(
          (uncanceledTest) => uncanceledTest.testId === test.testId && uncanceledTest.isUncanceled
        )[0];

        if (test.cancelReasonCode && test.testId !== isUncanceledTest?.testId) {
          canceledTests.push(new CanceledTest(test));
        }
      });
    }

    return canceledTests;
  }

  static createOrderFromOrderForm(
    isExistingAccession: boolean,
    orderForm: FormGroup,
    customer: Customer,
    missingInformationGlyph,
    originatingLabId: string,
    validationId: string,
    sampleTestAssociations?: SampleTestAssociation[],
    manuallyBlockedContextualizedTestIds?: string[],
    electronicOrderVersion?: number
  ): Order {
    let petOwnerBillingEnabled = customer ? customer.petOwnerBillingRequired : false;
    let customerPayload: any;
    let animalTypePayload: any;
    let tagsPayload: Tags;

    let patientAgePayload;
    const patientAge = orderForm.get('patientAge').value;

    if (patientAge) {
      const hasOrderableAge =
        patientAge &&
        patientAge.age &&
        (patientAge.age.years || patientAge.age.months || patientAge.age.weeks || patientAge.age.days);

      if (hasOrderableAge) {
        patientAgePayload = { age: patientAge.age };
      } else if (!hasOrderableAge && patientAge.dateOfBirth) {
        patientAgePayload = { dateOfBirth: patientAge.dateOfBirth };
      }
    }

    if (customer) {
      customerPayload = customer.customerId;
    }

    if (orderForm.get('animalType').value === missingInformationGlyph) {
      animalTypePayload = missingInformationGlyph;
    } else {
      animalTypePayload = ObjectUtil.swap(orderForm.get('animalType'), (v) => v?.value?.animalTypeCode || null);
    }

    // For accession edits the shape of the patient animal type is closer to reference data
    if (!animalTypePayload) {
      animalTypePayload = orderForm.get('animalType').value.animalTypeCode;
    }

    // Tags are not yet fully implemented in the backend, so we are comfortable hardcoding these values as
    // a first pass. As the design evolves a more robust, type-based approach will replace this implementation.
    tagsPayload = {};
    if (orderForm.get('courierPickup') && orderForm.get('courierPickup').value) {
      tagsPayload[KnownTags.DELIVERY] = 'courier';
    }
    if (orderForm.get('escalateToCustomerSupport') && orderForm.get('escalateToCustomerSupport').value) {
      tagsPayload[KnownTags.ESCALATION] = 'customer-support';
    }

    const accessionNumber =
      orderForm.get('accessionNumber') && orderForm.get('accessionNumber').value.trim().toUpperCase();
    const body: any = {
      addOnTestIds: this.getAddOnTestIds(orderForm.get('testCodes').value) || [],
      canceledTests: this.getCanceledTests(orderForm.get('testCodes').value) || [],
      manuallyBlockedContextualizedTestIds: manuallyBlockedContextualizedTestIds || [],
      patient: {
        name: orderForm.get('patientName') && orderForm.get('patientName').value.trim(),
        sex: ObjectUtil.swap(orderForm.get('patientSex').value, (v) => (v ? v.code : undefined)),
      },
      customer: {
        customerId: customerPayload,
      },
      owner: {
        name: orderForm.get('ownerName') && orderForm.get('ownerName').value.trim(),
      },
      originatingLabId,
      accessionNumber,
      validationId,
    };

    // Pet owner billing does not exist in all operational regions
    if (orderForm.get('owner')?.value) {
      const _value = orderForm.get('owner').value;
      const ownerAddress = OwnerAddress.createOwnerAddressFromPOBAddress(_value);
      const fiscalCode = StringUtil.trim(_value.fiscalCode);

      if (ownerAddress.hasSavableValue()) {
        body.owner.address = ownerAddress;
        petOwnerBillingEnabled = true;
      }

      if (fiscalCode) {
        body.owner.fiscalCode = fiscalCode;
      }
    }

    const orderedTestIds = this.getOrderedTestIds(orderForm.get('testCodes').value);

    const optional = {
      petOwnerBillingEnabled,
      requisitionId: StringUtil.trim(orderForm.get('requisitionInfo').value?.requisitionId),
      electronicOrderId: StringUtil.trim(orderForm.get('requisitionInfo').value?.electronicOrderId),
      electronicOrderVersion: electronicOrderVersion,
      collectionDate: orderForm.get('collectionDate') && orderForm.get('collectionDate').value,
      cancelReasonCode: orderForm.get('cancelReasonCode').value,
      testIds:
        orderForm.get('testCodes').value !== missingInformationGlyph ? orderedTestIds || [] : [missingInformationGlyph],
      pimsOrderId: StringUtil.trim(orderForm.get('pimsOrderId').value),
      vet: ObjectUtil.swap(StringUtil.trim(orderForm.get('vetName').value), (v) => (v ? { name: v } : undefined)),
      patient: {
        microChip: StringUtil.trim(orderForm.get('microchipId').value),
        ...patientAgePayload,
        animalTypeCode: animalTypePayload,
        customAnimalType: orderForm.get('customAnimalType') && orderForm.get('customAnimalType').value,
      },
      discountCode: ObjectUtil.swap(orderForm.get('discount').value, (v: Discount) => v && v.code),
      copyToEmails: ObjectUtil.swap(orderForm.get('emailFax').value, (v) => v && v.emails),
      copyToFaxNumbers: ObjectUtil.swap(orderForm.get('emailFax').value, (v) => v && v.faxNumbers),
      customerMessages: (orderForm.get('customerMessages').value || []).map(
        ({
          value,
          info,
        }: {
          value: any;
          info: {
            dateCreated: string;
            modified: boolean;
            text: string;
          };
        }) => ({
          customerMessageId: value && value.customerMessageId,
          accountType: value?.accountType,
        })
      ),
      customerBarcode: orderForm.get('customerBarcodeId')?.value
        ? StringUtil.trim(orderForm.get('customerBarcodeId').value.toString())
        : '',
      samples:
        orderForm.get('samples').value.missingSample && !orderForm.get('samples').value.samples.length
          ? missingInformationGlyph
          : (orderForm.get('samples').value.samples || []).map((sample) =>
              Sample.createSavableSample(sample, isExistingAccession, sampleTestAssociations)
            ),
      tags: tagsPayload,
    };

    ObjectUtil.merge(
      body,
      ObjectUtil.delete(optional, (v) => {
        if ((v && typeof v === 'object') || v === 0) {
          if (Array.isArray(v)) {
            return v.length;
          } else {
            return !!(Object.keys(v).length || v === 0);
          }
        }

        return v;
      })
    );

    return body;
  }
}
