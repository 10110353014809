import { Injectable } from '@angular/core';
import { OperationalRegionComponent } from './operational-region.component';
import { ElectronicOrder } from '../../shared/models/electronic-order.model';
import { forkJoin } from 'rxjs';
import { Response } from '../../shared/services/response/response';
import { Customer } from '../../shared/models/customer.model';
import { Test } from '../../shared/models/test.model';
import { AnimalTypeInputValue } from '../../shared/models/animal-type.model';
import { PatientAgeDOB } from '../../shared/modules/age-dob/age-dob.model';
import { OrderEntryService } from '../order-entry.service';
import { OrderValidationService } from '../order-validation/order-validation.service';
import { TranslateService } from '@ngx-translate/core';
import { ElectronicOrderService } from '../electronic-order/electronic-order.service';

@Injectable({
  providedIn: 'root',
})
export class ElectronicOrderComponentService {
  #component: (OperationalRegionComponent | null) = null;

  constructor(
    private orderEntryService: OrderEntryService,
    private orderValidationService: OrderValidationService,
    private electronicOrderService: ElectronicOrderService,
    private translate: TranslateService
  ) {}

  setComponent(component: OperationalRegionComponent) {
    if (this.#component !== null) {
      throw new Error('Operational Region Component already initialized for Electronic Orders. This service instance should not be reused');
    }

    this.#component = component;
  }

  removeComponent() {
    this.#component = null;
  }

  hasComponent(): boolean {
    return this.#component !== null;
  }

  handleElectronicOrder(electronicOrder: ElectronicOrder) {
    if (this.hasComponent()) {
      const component = this.#component;

      if (!electronicOrder) {
        this.handleMissingElectronicOrder(electronicOrder);

        return;
      }

      const isCanceled = electronicOrder.status === 'CANCELED';

      component.orderEntryForm.get('testCodes').enable();

      this.orderEntryService.electronicOrderVersion = electronicOrder.version;

      component.testCodes.resetUnresolvedEorderTestCodes();

      const requisitionId = component.orderEntryForm.controls.requisitionInfo.value.requisitionId;

      this.setElectronicOrderFormData(electronicOrder);

      component.orderEntryForm.get('courierPickup').reset();
      component.discount.resetInputValue();
      component.orderEntryForm.get('discount').reset();
      component.samples.resetInputValue();
      component.orderEntryForm.get('samples').patchValue({
        samples: [],
        missingSample: false,
      });
      component.customerMessages.resetList();
      component.orderEntryForm.get('customerMessages').reset();

      if (component.orderSaveResponseReceived && !component.orderSaveSuccess) {
        component.reqIdDirtyScanDetected(requisitionId);
      }

      // Clear any existing order validations on order in case of existing entries
      this.orderValidationService.reset(
        component.orderEntryForm.get('testCodes').value,
        this.orderEntryService.missingInformationGlyph
      );
      this.orderValidationService.orderValidationId = null; // should be cleared since samples are removed on electronic order scan
      component.resetSamplesAssociations();
      component.orderEntryForm.get('testCodes').reset();

      forkJoin(
        [
          this.electronicOrderService.findElectronicOrderCustomers(
            electronicOrder,
            component.orderEntryForm.controls.customerCode.value
          ),
          this.electronicOrderService.findRequisitionAnimalType(electronicOrder),
        ].concat(component.testCodes.findElectronicOrderTests(electronicOrder.tests))
      ).subscribe(this.setupElectronicOrderForm(electronicOrder, isCanceled, requisitionId));
    }
  }

  private handleMissingElectronicOrder(electronicOrder: any) {
    const component = this.#component;
    component.loadingOrderByReqId = false;

    if (electronicOrder !== null) {
      this.orderEntryService.electronicOrderVersion = null;
      component.orderEntryForm.patchValue({
        requisitionInfo: { requisitionId: '', electronicOrderId: '', electronicOrderVersion: null },
      });
    }

    setTimeout(() => {
      if (this.hasComponent()) {
        component.requisitionId.focus();
      }
    });

    if (!component.isExistingAccession) {
      component.clearPersistedData();
    }
  }

  private setupElectronicOrderForm(electronicOrder: ElectronicOrder, isCanceled: boolean, requisitionId: string) {
    const component = this.#component;
    
    return {
     next: ([customerResponse, animalTypeResponse, ...orderedTestsResponse]) => {
       component.handleCustomerSearchResponse((customerResponse as Response<Customer[]>).data, false);

       component.handleAnimalTypeResponse(animalTypeResponse as AnimalTypeInputValue);

       const addedTests = orderedTestsResponse.filter((ele) => ele !== null) as Test[];

       if (isCanceled && !component.testCodes.unresolvedEorderTestCodes.length) {
         addedTests.forEach((test) => {
           test.cancelReasonCode = 'CUSTOMER_REQUEST';
         });
       }

       component.testCodes.writeAddedTests(addedTests);

       if (electronicOrder.customerMessages) {
         electronicOrder.customerMessages.forEach((recommendedMessageId) => {
           component.availableCustomerMessages.forEach((customerMessage) => {
             if (customerMessage.customerMessageId === recommendedMessageId) {
               setTimeout(() => {
                 component.customerMessages.add(component.customerMessages.searchChar + customerMessage.shortCode, true);
               }, 0);
             }
           });
         });
       }

       if (isCanceled) {
         if (!component.testCodes.unresolvedEorderTestCodes.length) {
           component.orderEntryForm.get('testCodes').disable();
         }
       }
     },
     error: (err) => {
       // ERROR
       component.logAction('oelog-electronic-order-search-failure', this.orderEntryService.getLogPayload(requisitionId));
       component.logAction('oelog-electronic-order-search-error', err);

       component.loadingOrderByReqId = false;
     },
     complete: () => {
       // COMPLETE
       component.logAction('oelog-electronic-order-add', this.orderEntryService.getLogPayload(requisitionId));
       component.loadingOrderByReqId = false;

       setTimeout(() => {
         if (!component.customerSearch.visible && this.hasComponent()) {
           component.samples.focusInput();
         }
       }, 0);
     },
   };
  }

  setElectronicOrderFormData(electronicOrder: ElectronicOrder) {
    const component = this.#component;
    
    if (!component.orderEntryForm.controls.customerCode.value && electronicOrder.customerCode) {
      component.orderEntryForm.controls.customerCode.setValue(electronicOrder.customerCode);
    }

    if (electronicOrder.collectionDate) {
      component.orderEntryForm.get('collectionDate').setValue(electronicOrder.collectionDate);
    }

    if (electronicOrder.vet) {
      component.orderEntryForm.get('vetName').reset();
      component.orderEntryForm.controls.vetName.setValue(electronicOrder.vet);
    }

    if (electronicOrder.owner) {
     this.setupElectronicOrderOwnerData(electronicOrder);
    }

    if (electronicOrder.patient) {
      this.setupElectronicOrderPatientData(electronicOrder);
    }
  }

  setupElectronicOrderOwnerData(electronicOrder: ElectronicOrder) {
    const component = this.#component;
   
    if (electronicOrder.owner.name) {
      component.orderEntryForm.get('ownerName').reset();
      component.orderEntryForm.controls.ownerName.setValue(electronicOrder.owner.name);
    }

    if (component.petOwnerBilling) {
      this.setupElectronicOrderPOBData(electronicOrder);
    }
  }

  setupElectronicOrderPOBData(electronicOrder: ElectronicOrder) {
    const component = this.#component;
    
    component.petOwnerBilling.reset();

    if (electronicOrder.owner.address?.city) {
      component.petOwnerBilling._form.controls.city.setValue(electronicOrder.owner.address.city);
    }

    if (electronicOrder.owner.address?.countryCode) {
      component.petOwnerBilling._form.controls.countryCode.setValue(electronicOrder.owner.address.countryCode);
    }

    if (electronicOrder.owner.address?.postalCode) {
      component.petOwnerBilling._form.controls.postalCode.setValue(electronicOrder.owner.address.postalCode);
    }

    if (electronicOrder.owner.address?.street1) {
      component.petOwnerBilling._form.controls.street1.setValue(electronicOrder.owner.address.street1);
    }

    if (electronicOrder.owner.fiscalCode) {
      component.petOwnerBilling._form.controls.fiscalCode.setValue(electronicOrder.owner.fiscalCode);
    }

    if (electronicOrder.owner.address?.province) {
      component.petOwnerBilling._form.controls.province.setValue(electronicOrder.owner.address.province);
    }

    component.petOwnerBilling.save(null, true);
  }
  
  setupElectronicOrderPatientData(electronicOrder: ElectronicOrder) {
    const component = this.#component; 
   
    if (electronicOrder.patient.name) {
      component.orderEntryForm.get('patientName').reset();
      component.orderEntryForm.get('patientName').patchValue(electronicOrder.patient.name);
    }

    // we use the incoming patient sex code to find the match in our reference data
    if (electronicOrder.patient.sex) {
      component.orderEntryForm.get('patientSex').reset();
      component.availablePatientSexes.forEach((availablePatientSex) => {
        if (electronicOrder.patient.sex.code === availablePatientSex.code) {
          component.orderEntryForm.controls.patientSex.setValue(availablePatientSex, { emitEvent: false });
        }
      });
    }

    if (electronicOrder.patient && electronicOrder.patient.dateOfBirth) {
      component.orderEntryForm.get('patientAge').reset();
      component.orderEntryForm
        .get('patientAge')
        .setValue(new PatientAgeDOB(null, electronicOrder.patient.dateOfBirth, this.translate));
    }

    component.orderEntryForm.get('microchipId').reset();

    if (electronicOrder.patient.microChip) {
      component.orderEntryForm.controls.microchipId.setValue(electronicOrder.patient.microChip);
    }
  }
}
