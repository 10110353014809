import { Injectable } from '@angular/core';
import { OperationalRegionComponent } from './operational-region.component';
import { Test } from '../../shared/models/test.model';
import { AppStateService } from '../../app-state.service';
import { AccessionedSample, Sample } from '../../shared/models/sample.model';
import { OrderEntryService, OrderIdResource } from '../order-entry.service';
import { Accession } from '../../shared/models/accession.model';
import { OVResponse } from '../../shared/models/ov-response';
import { LoggerService } from '@lims-common-ux/lux';
import { OrderValidationService } from '../order-validation/order-validation.service';

export interface OrderValidationChanges {
  newTests: Test[];
  samplesChanged: boolean;
  animalTypeChanged: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class OrderValidationComponentService {
  #component: (OperationalRegionComponent | null) = null;

  constructor(
    private appStateService: AppStateService,
    private loggerService: LoggerService,
    private orderValidationService: OrderValidationService,
    private orderEntryService: OrderEntryService
  ) {}

  setComponent(component: OperationalRegionComponent) {
    if (this.#component !== null) {
      throw new Error('Operational Region Component already initialized for Order Validation. This service instance should not be reused');
    }

    this.#component = component;
  }

  removeComponent() {
    this.#component = null;
  }

  getOrderValidationChanges(): OrderValidationChanges {
    const component = this.#component;

    const changes: OrderValidationChanges = {
      newTests: [], // tests that are not yet saved
      samplesChanged: false,
      animalTypeChanged: false,
    };
    let tests: Test[] = component.orderEntryForm.get('testCodes').value;
    let samples: Sample[] = component.orderEntryForm.get('samples').value?.samples;

    if (this.appStateService.existingAccession) {
      if (!tests) {
        tests = [];
      }

      if (!samples) {
        samples = [];
      }

      let sampleChanges = [];

      if (Array.isArray(tests)) {
        changes.newTests = this.getTestChanges(tests);
      }

      if (Array.isArray(samples)) {
        sampleChanges = this.getSampleChanges(samples);
      }

      if (sampleChanges?.length || component.samplesHaveChanged) {
        changes.samplesChanged = true;
        component.samplesHaveChanged = true;
      }

      changes.animalTypeChanged = component.animalTypeHasChanged;
    }

    return changes;
  }

  private getTestChanges(tests: Test[]): Test[] {
    const result: Test[] = [];

    tests.forEach((test) => {
      let fnd = false;

      this.appStateService.existingAccession.orderedTests.forEach((exTest) => {
        if (!fnd && test.testId === exTest.testId) {
          fnd = true;

          let existingTestWasCanceled = false;

          this.appStateService.existingAccession.canceledTests.forEach((cTest) => {
            if (cTest.testId === exTest.testId) {
              existingTestWasCanceled = true;
            }
          });

          if (!test.cancelReasonCode && existingTestWasCanceled) {
            fnd = false;
          }
        }
      });

      if (!fnd) {
        result.push(test);
      }
    });

    return result;
  }

  private getSampleChanges(samples: any[]): AccessionedSample[] {
    const result = [];

    samples.forEach((sample) => {
      let fnd = false;

      this.appStateService.existingAccession.samples.forEach((exSample) => {
        if (!fnd && sample.id === exSample.id) {
          fnd = exSample?.draw?.value === sample?.draw?.value;

          if (exSample?.collectionMethod?.value !== sample?.collectionMethod?.value) {
            fnd = false;
          }

          if (exSample?.temperature?.value !== sample?.temperature?.value) {
            fnd = false;
          }

          if (exSample?.status !== sample.status || sample.isRemoved) {
            fnd = false;
          }
        }
      });

      if (!fnd) {
        result.push(sample);
      }
    });

    return result;
  }

  private readySamplesForOrderValidation(): Sample[] {
    const component = this.#component;
    let _samples: any;

    if (component.orderEntryForm.get('samples').value && component.orderEntryForm.get('samples').value.samples.length > 0) {
      _samples = component.orderEntryForm.get('samples').value.samples;
    }

    if (Array.isArray(_samples)) {
      _samples = component.filterRemovedSamples(_samples);
    }

    if (component.orderEntryForm.get('samples').value?.missingSample) {
      _samples = this.orderEntryService.missingInformationGlyph;
    }

   if (
        _samples &&
        _samples.length &&
        _samples === this.orderEntryService.missingInformationGlyph &&
        !component.samples.input.nativeElement.value
      ) {
        _samples = [];
      }

    return _samples;
  }

  private readyNewTests(): Test[] {
    const component = this.#component;
    let newTests = [];
    if (Array.isArray(component.orderEntryForm.get('testCodes').value)) {
      newTests = component.orderEntryForm.get('testCodes').value.filter((item) => {
        return component.ovFieldChanges.newTests.filter(
          (newTest) => newTest.contextualizedTestId === item.contextualizedTestId
        ).length > 0
          ? item
          : false;
      });
    }
    return newTests;
  }

  private readyOrderValidationSubscription(resource: OrderIdResource | Accession, _animalType: string, _samples: Sample[], tests: Test[] | string, runPartially: boolean) {
    const component = this.#component;

    return this.orderValidationService
     .validateOrder(
       component.lab.id,
       resource,
       _animalType,
       _samples,
       tests,
       this.orderEntryService.missingInformationGlyph
     )
     .subscribe({
       next: (response) => {
         let orderValidationResponse;
         orderValidationResponse = response.body as OVResponse;

         component.orderValidationRequested = false;
         component.orderValidationReceived = true;

         this.orderValidationService.orderValidationId = orderValidationResponse['validationId'];

         this.loggerService.logAction(
           'oelog-order-validation-id-added',
           this.orderValidationService.orderValidationId
         );

         // Parse orderValidationResponse compared to ordered items
         if (Array.isArray(tests)) {
           this.orderValidationService.resetUserChanges();

           this.orderValidationService.parseRecommendations(tests, orderValidationResponse, runPartially);
         }

         // Display recommended customer messages
         if (orderValidationResponse['actions'] && orderValidationResponse['actions'].customerMessages) {
           // we want to overwrite the current available customer message decline link
           // with the value returned from order validation
           orderValidationResponse['actions'].customerMessages.forEach((recommendedMessage) => {
             component.availableCustomerMessages.forEach((customerMessage) => {
               if (customerMessage.customerMessageId === recommendedMessage.customerMessageId) {
                 customerMessage['_links'] = recommendedMessage._links;

                 component.customerMessages.add(component.customerMessages.searchChar + customerMessage.shortCode, true);
               }
             });
           });
         }

         // Set displayType for order save button color/style
         this.orderValidationService.setOrderDisplayType();
       },
       error: (err) => {
         this.loggerService.logAction('oelog-order-validation-failure', err);
       },
     });
  }

  private runOrderValidation(_animalType: string, _samples: Sample[], tests: Test[] | string, fullValidation: boolean, runPartially: boolean) {
    const component = this.#component;

    if (
      _animalType &&
      _samples?.length > 0 &&
      (tests?.length > 0 || tests === this.orderEntryService.missingInformationGlyph)
    ) {
      component.orderValidationRequested = true;
      component.orderValidationReceived = false;

      this.orderValidationService.orderValidationId = null;

      let resource: OrderIdResource | Accession = component.orderResource;

      if (this.appStateService.existingAccession) {
        resource = this.appStateService.existingAccession;
      }

      // Remove recommended customer messages for re-validation
      if (!this.appStateService.existingAccession || fullValidation) {
        component.customerMessages.purgeRecommendations();
      }

      component.orderValidationSub = this.readyOrderValidationSubscription(resource, _animalType, _samples, tests, runPartially);
    } else if (_animalType && _samples?.length > 0 && tests?.length === 0 && runPartially) {
      this.orderValidationService.orderValidationId = null;
    }
  }

  readyOrderValidation() {
    const component = this.#component;
    
    component.orderValidationRequested = false;
    component.orderValidationReceived = false;

    if (component.orderValidationSub) {
      component.orderValidationSub.unsubscribe();
    }

    const _animalType = component.orderEntryForm.get('animalType').value;
    const _samples = this.readySamplesForOrderValidation();
    let _tests: Test[] | string = component.orderEntryForm.get('testCodes').value;
    const fullValidation: boolean =
      component.ovFieldChanges.animalTypeChanged ||
      component.ovFieldChanges.samplesChanged ||
      !!(
        this.appStateService.existingAccession &&
        this.appStateService.existingAccession.orderedTests.length === 0 &&
        _tests
      );

    this.orderValidationService.reset(_tests, this.orderEntryService.missingInformationGlyph);

    if (Array.isArray(_tests)) {
      _tests = _tests.filter((addedTest) => !addedTest?.cancelReasonCode);
    }

    const runPartially =
      !!this.appStateService.existingAccession &&
      !component.ovFieldChanges.samplesChanged &&
      !component.ovFieldChanges.animalTypeChanged;
    let tests: Test[] | string;

    if (this.appStateService.existingAccession) {
      const existingTests = _tests.length ? _tests : this.orderEntryService.missingInformationGlyph;
      const newTests = this.readyNewTests();

      tests = runPartially ? newTests : existingTests;
    } else {
      tests = _tests;
    }

    this.runOrderValidation(_animalType, _samples, tests, fullValidation, runPartially);
  }

  readyDeclineRecommendation(item) {
    const component = this.#component;

    if (item.value._links && item.value._links.decline) {
      component.preventSave = true;
      this.orderValidationService.declineBlockRecommendation(item).subscribe((confirmation) => {
        component.preventSave = false;
        item.info.recommended = false;
        if (
          this.orderValidationService.orderValidationResponse &&
          this.orderValidationService.orderValidationResponse['actions']
        ) {
          this.orderValidationService.orderValidationResponse['actions'].customerMessages.forEach(
            (recommendedMessage, index) => {
              if (item.value.customerMessageId === recommendedMessage.customerMessageId) {
                this.orderValidationService.orderValidationResponse['actions'].customerMessages.splice(index, 1);
              }
            }
          );
        }
        component.customerMessages.delete(item);
        this.orderValidationService.setOrderDisplayType();
      });
    } else {
      item.info.recommended = false;
      component.customerMessages.delete(item);
    }
  }
}
